<template>
  <div class="max-w-5xl px-5 mx-auto mt-6">
    <h1 class="text-secondary font-semibold text-3xl mb-3 font-secondary uppercase">Cotización</h1>
    <div class="mb-3">
      <span class="text-xs font-bold text-gray-600 uppercase">Lugar de Trabajo:</span>

      <PlacesInput class="w-1/2" />
      <div
        v-if="cart.length > 0 && quote"
        class="border rounded-lg text-center text-lg divide-y my-4"
      >
        <div class="divide-gray-200 divide-y -my-px" aria-disabled="true">
          <ItemDetails
            v-for="(item, $index) in formated.items"
            :key="$index"
            :item="item.item"
            :details="item.details"
            class="p-3 hover:bg-gray-100"
            :remove-from-cart="removeFromCart"
          />
        </div>
        <div class="bg-opacity-50 bg-white hover:bg-gray-100">
          <div class="block p-3 space-y-2">
            <div class="grid grid-cols-2">
              <div class="space-y-2">
              </div>
              <div class="space-y-2 md:mx-20">
                <div class="flex justify-between">
                  <div class="text-sm text-primary font-semibold">Subtotal</div>
                  <div class="tracking-wider text-sm text-gray-600 font-semibold">{{ currency(quote.sub_total) }}</div>
                </div>
                <div class="flex justify-between ">
                  <div class="text-sm text-primary font-semibold">IVA 16%</div>
                  <div class="tracking-wider text-sm text-gray-600 font-semibold">{{ currency(quote.taxes) }}</div>
                </div>
                <div class="flex justify-between ">
                  <div class="text-secondary font-semibold">TOTAL</div>
                  <div class="tracking-wider text-secondary font-semibold">{{ currency(quote.total) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="cart.length == 0" class="text-lg mt-12">
        No hay articulos en tu carrito
      </div>
      <div v-else class="text-lg mt-12">
        Selecciona una ubicacion de trabajo
      </div>
      <div v-show="cart.length > 0" class="mt-20 justify-center flex">
        <router-link
          v-show="quote"
          class="ml-4 btn btn-orange-success"
          :class="{'opacity-50 pointer-events-none': !canProceed}"
          :to="{ name: 'order_confirmation' }"
        >
          <r-icon type="check-circle" class="h-6 mr-3 inline-block" /> Confirmar Pedido
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, watch } from 'vue';
import http from '@/http-common';
import PlacesInput from '@/components/inc/inputs/AlgoliaPlaces';
import ItemDetails from '@/components/Operations/ItemDetails.vue';
import { currency } from '@/filters';
import { removeFromCart, setQuote, useQuote, useCart, useEndDate, useLocation, useStartDate } from '@/store/shoppingCart';

export default {
  components: {
    ItemDetails,
    PlacesInput,
  },
  setup() {
    const cart = useCart();
    const quote = useQuote();
    const location = useLocation();
    const startDate = useStartDate();
    const endDate = useEndDate();

    async function requestQuote() {
      if(!location || !endDate || !startDate) return;
      const response = await http.post('/api/carts/quote', {cart: {...cart.value}});
      setQuote(response.data);
    }

    onBeforeMount(() => requestQuote());
    watch([cart, location], () => requestQuote(), { deep: true });

    return { cart, currency, location, removeFromCart, quote };
  },
  computed: {
    canProceed() {
      return !this.quote?.items.some(item => item.available === false);
    },
    formated() {
      return {
        items: this.quote.items.map(item => ({
          item,
          details: {
            delivery_fee: item.delivery_fee,
            insurance_fee: item.insurance_fee,
            sub_total: item.sub_total,
          },
        })),
        resume: {
          sub_total: this.quote.subtotal,
          tax: this.quote.tax,
          total: this.quote.total,
        },
      };
    },
  },
};
</script>
