<template>
  <div class="max-w-5xl px-5 mx-auto mt-6 text-justify">
    <div class="text-center text-4xl font-bold mb-3">
      A 3 pasos de renta de equipo para
      <br />
      Tu proyecto
    </div>
    <div class="flex flex-wrap text-gray-700">
      <div class="flex flex-col md:w-1/2 md:p-16 p-8">
        <h2 class="text-2xl font-bold">1. Conoce</h2>
        <p>
          El primer comienzo es conocer las necesidades detu proyecto, Alturas,
          Terreno, Tiempo que ocupasel equipo, Cargas, Accesos con el fin de
          saber cuales el equipo que en verdad se adapta a tusnecesidades y te
          ayude a resolver problemas
        </p>
      </div>
      <div
        class="md:flex flex-col justify-center md:w-1/2 p-16 sm:inline hidden"
      >
        <img
          :src="require('@/assets/faq1.jpg')"
          alt="..."
          class="shadow rounded max-w-full h-auto align-middle border-none"
        />
      </div>
      <div
        class="md:flex flex-col justify-center md:w-1/2 p-16 sm:inline hidden"
      >
        <img
          :src="require('@/assets/faq2.jpg')"
          alt="..."
          class="shadow rounded max-w-full h-auto align-middle border-none"
        />
      </div>
      <div class="flex flex-col md:w-1/2 md:p-16 p-8">
        <h2 class="text-2xl font-bold">2. Reserva</h2>
        <p>
          Ya que econtraste el equipo que se adapta a tusnecesidades Reservae
          informate sobre todos losrequisitos como documentos que puedes llegar
          aocupar para poder generar una orden de compra,te podras dar cuenta
          con los metodos de pago conlos que contamos, puedes apoyarte con el
          equipode Call-Center con el que contamos.
        </p>
      </div>
      <div class="flex flex-col md:w-1/2 md:p-16 p-8">
        <h2 class="text-2xl font-bold">3. Opera</h2>
        <p>
          !Estamos Listos¡, Ponte en contacto con el serviciode soporte de
          RENTALO a traves de la plataformadonde daremos seguimiento a la
          entrega asi comoposibles dudas que surgan durante el proceso
          yoperacion del equipo en el tiempo de Renta.
        </p>
      </div>
      <div
        class="md:flex flex-col justify-center md:w-1/2 p-16 sm:inline hidden"
      >
        <img
          :src="require('@/assets/faq3.jpg')"
          alt="..."
          class="shadow rounded max-w-full h-auto align-middle border-none"
        />
      </div>
    </div>
    <div class="flex flex-wrap p-8 border-grey-200 border-t">
      <div class="text-2xl font-bold mb-3 flex flex-col md:w-1/3">
        <p>
          En cualquier lugar donde ocupes
          <span class="text-primary">Rentar</span>, nosotros te ayudamos
        </p>
      </div>
    </div>
    <div class="flex flex-wrap text-left">
      <div class="flex flex-col md:w-1/3 p-8">
        <h2 class="text-2xl font-bold">Seguridad</h2>
        <p>
          Todas tus rentas estanasegurada por los dias queocupes el equipo ya
          quenuestra prioridad es laseguridadtanto del equipocomo del usuario al
          momentode operar cualquier equipo.
        </p>
      </div>
      <div class="flex flex-col md:w-1/3 p-8">
        <h2 class="text-2xl font-bold">Calidad</h2>
        <p>
          Nuestro fin es unirnos a tucausa en tu proyecto, paracumplir nuestra
          meta, tenemoscomo tarea revisar cadaequipo para garantizar unacalidad
          de primera.
        </p>
      </div>
      <div class="flex flex-col md:w-1/3 p-8">
        <h2 class="text-2xl font-bold">Asistencia las 24 horas</h2>
        <p>
          Estamos contigo las 24 horas,los 7 dias a la semana, paraapoyarte en
          cualquierescenario en el que teencuentres.
        </p>
      </div>
    </div>
    <bg-image
      is="header"
      class="bg-cover bg-clip-border bg-gradient-to-b from-primary to-secondary"
      over="linear-gradient(#ffffff33, #ffffff33)"
      under="linear-gradient(to bottom, var(--color-primary), var(--color-secondary))"
      :class="'w-full relative bg-center bg-no-repeat p-12 rounded-lg'"
      :src="require('@/assets/header-bg-02.png')"
    >
      <h2 class="text-3xl text-white font-bold">
        ¿Todo listo para empezar a buscar?
      </h2>
      <router-link :to="{ name: 'Home' }">
        <button
          class="
            focus:outline-none
            text-white
            rounded-md
            border-2
            p-2
            mt-4
            border-white
            font-bold
          "
        >
          Empezar Ahora
        </button>
      </router-link>
    </bg-image>
    <div class="flex flex-wrap p-8">
      <div class="text-2xl font-bold mb-3 flex flex-col md:w-1/3">
        <p>¿ Te queda alguna duda ?</p>
      </div>
      <div class="mb-3 flex flex-col md:w-2/3">
        <div
          v-for="(faq, index) in faqs"
          :key="index"
          class="border-grey-200 border-b"
        >
          <div class="">
            <a
              href="#"
              class="tab__link my-4 no-underline flex justify-between rounded"
              @click.prevent="faq.active = !faq.active"
            >
              <strong>{{ faq.name }}</strong>
              <span v-show="!faq.active" class="mx-4">&#10133;</span>
              <span v-show="faq.active" class="mx-4">&#10134;</span>
            </a>
          </div>
          <div v-show="faq.active" class="p-2" v-html="faq.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'Faqs',
  setup() {
    const faqs = ref([
      {
        id: 1,
        name: '¿ Tengo que contratar el servicio de flete para mi renta ?',
        text: '<p>Nuestra plataforma ya incluye cualquier tipo de servicio de logística para cualquier equipo de renta.  Durante el proceso de renta te iremos preguntando datos como ubicación y fechas para poder generar este servicio.</p>',
        active: false,
      },
      {
        id: 2,
        name: '¿ Que documetacion ocupo para rentar ?',
        text: '<ul><li>Acta constitutiva y poderes.</li><li>2 comprobantes de domicilio.</li><li>Identificación del representante legal de la compañía por ambos lados.</li><li>Alta en hacienda y cedula del RFC.</li><li>Carta de opinión de SAT de cumplimiento de obligaciones fiscales.</li></ul>',
        active: false,
      },
      {
        id: 3,
        name: '¿ Cuales son los metodos de pago con los que puedorealizar mi renta?',
        text: '<p>Tenemos dos métodos de pagos muy fáciles de llevar a acabo:</p><ul><li>Pago con <strong>tarjeta</strong></li><li>Pago con <strong>transferencia</strong></li></ul><p>Ambos métodos de pago se generan durante tu proceso de renta en plataforma RENTALOMX</p>',
        active: false,
      },
      {
        id: 4,
        name: '¿ Tengo alguna otra duda ?',
        text: '<p>Contáctanos a nuestro servicio de CENTRO DE ATENCIÓN TELEFÓNICA con el finde poder dar seguimiento tanto a dudas como apoyo durante el proceso de renta.</p>',
        active: false,
      },
      {
        id: 5,
        name: '¿ En caso de siniestro o daño, donde lo puedo reportar ?',
        text: '<p>El primero paso es contactar el CENTRO DE ATENCIÓN TELEFÓNICA para poder generar una carpeta de siniestro de maquinaria, nuestro equipo asistirá para poder recoger evidencia y dar proceso al seguro de la máquina.</p>',
        active: false,
      },
      {
        id: 6,
        name: '¿ Como puedo cancelar una renta ?',
        text: '<p>Las cancelaciones, ( quedamos al pendiente de esta respuesta hasta saber datos de penalizaciones de banco por transferencia o generar penalizaciones de renta en rentalo)</p>',
        active: false,
      },
    ]);
    return { faqs };
  },
};
</script>
